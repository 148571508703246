<template>
    <div class="st text-loop st-client ">
        <div class="container-fluid pb-3" ref="container">
            <div class="box-content text-center pb-md-3 pd-3">
                <h2 class="text-upper text-light text-start text-md-center mb-3 mb-md-0">
                    <b> Leading Brands <br class="d-md-none d-block"> and companies</b> <br > 
                    <span class="text-ctr-primary">
                        <b>from various<br class="d-md-none d-block">  industries</b>
                    </span>
                </h2>
            </div>
             <transition class="d-lg-block d-none" name="scroll">
                <div  class="element-client">
                    <div v-for="(groups, index) in itemGroups" :key="index" :class="[`client-group`, `client-group-${index}`]" >
                        <div class="list-item" v-for="i in groupClient" :key="i" >
                            <div class="item" :class="[`${itemI}`]" v-for="itemI in groups" :key="itemI" ><img :src="require(`../../assets/image/home/client/client-logo-${itemI}.webp`)" alt=""></div>
                        </div>
                    </div>
                </div>
            </transition>

            <div  class="element-client d-lg-none d-block">
                <div class="client-group">
                    <div class="list-item row">
                        <div class="col-3 item" v-for="itemI in 33" :key="itemI" ><img :src="require(`../../assets/image/home/client/client-logo-${itemI}.webp`)" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
export default {
    data() {
        return {
        x: 0,
        numberOfClones:1,
        groupClient:'',
        groupIndex:'',
        itemClient:33,
        listWidth:'',
        items: Array.from({length: 33}, (_, i) => i+1)
        }
    },
    mounted(){
        this.scrollingAuto();
        window.addEventListener('load', () => {
            this.scrollingAuto();
            this.keyframesInIt();
        });
        window.addEventListener('resize', () => {
            this.scrollingAuto();
            this.removeStyleKey();
            this.keyframesInIt();
           
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', () =>{
            this.scrollingAuto();
            this.removeStyleKey();
            this.keyframesInIt();
            
        });
    },
    methods: {
        scrollingAuto() {
            var refMod;
            if (window.innerWidth > 1919) {
                refMod = this.itemClient%15;
                console.log(this.itemClient/refMod , 10);
                if (this.itemClient/refMod >= 10) {
                    this.groupClient = refMod;
                    this.groupIndex = this.groupClient;
                    this.groupClient += 2;
                }else{
                    this.groupClient = 1;
                }
            }else if (window.innerWidth > 1439) {
                refMod = this.itemClient%15;
                if (this.itemClient/refMod >= 10) {
                    this.groupClient = refMod;
                    this.groupIndex = this.groupClient;
                    this.groupClient += 2;
                }else{
                    this.groupClient = 1;
                }
            }else if (window.innerWidth > 1199) {
                refMod = this.itemClient%15;
                if (this.itemClient/refMod >= 10) {
                    this.groupClient = refMod;
                    this.groupIndex = this.groupClient;
                    this.groupClient += 2;
                }else{
                    this.groupClient = 1;
                }
            }else if (window.innerWidth > 991) {
                refMod = this.itemClient%15;
                if (this.itemClient/refMod >= 10) {
                    this.groupClient = refMod;
                    this.groupIndex = this.groupClient;
                    this.groupClient += 3;
                }else{
                    this.groupClient = 1;
                }
            }else if (window.innerWidth > 767) {
                refMod = this.itemClient%10;
                if (this.itemClient/refMod > 5) {
                    this.groupClient = refMod;
                    this.groupIndex = this.groupClient;
                    this.groupClient += 1;
                }else{
                    this.groupClient = 1;
                }
            }else if (window.innerWidth > 0){
                refMod = this.itemClient%18;
                if (this.itemClient/refMod < 10) {
                    this.groupClient = refMod;
                    this.groupIndex = this.groupClient;
                    
                }else{
                    this.groupClient = 3;
                }
            }

            
        },

        keyframesInIt(){
            document.querySelectorAll(".client-group").forEach((element,index) => {
                    var count = element.querySelectorAll('.item').length;
                    this.listWidth =  (count*150);
                    const listItemW = window.innerWidth / this.listWidth ;
                
                    let rounded;
                    if (listItemW - Math.floor(listItemW) < 0.5) {
                        rounded = Math.floor(listItemW);
                    } else {
                        rounded = Math.ceil(listItemW);
                    }

                    this.numberOfClones = rounded+1;

                    let keyframes = `@keyframes scroll-${index} {
                        0% {
                            transform: translateX(0);
                        }
                        100% {
                            transform: translateX(` + (index % 2 == 0 ? `-${(this.listWidth/this.groupClient)}px` : `${(this.listWidth/this.groupClient)}px`) + `);
                        }
                    }
                    .st-client .client-group-${index} .list-item {animation: scroll-${index} 20s linear infinite;display: flex;width: auto;}`

                    
                   setTimeout(() => {
                    let style = document.createElement('style');
                    style.setAttribute('data-unique-id', 'my-unique-id');
                    style.innerHTML = keyframes;
                    document.head.appendChild(style);
                   }, 300);
                    
                });
        },

        removeStyleKey(){
            let clientGroups = document.querySelectorAll('.client-group');
            let oldStyles = document.querySelectorAll('style[data-unique-id="my-unique-id"]');
            if (clientGroups.length > 0 && oldStyles) {
                clientGroups.forEach(() => {
                    oldStyles.forEach(oldStyle => {
                        oldStyle.remove();
                    });
                });
            }
        }
    },
    computed: {
        itemGroups() {
            let groups = []
            let middle = Math.ceil(this.items.length / this.groupIndex)
            for (let i = 0; i < this.items.length; i += middle) {
            groups.push(this.items.slice(i, i + middle))
            }
            return groups
        }
  }
}
</script>
<style>
.st-client{padding: 100px 0;}
.element-client { width: calc(100% + 30px); margin-left: -15px;}
.st-client.text-loop{margin-top: 0;}
.st-client .client-group{margin: auto;position: relative;overflow: hidden; width: auto;display: flex;}
.st-client .client-group .item {height: 100px;width: 150px; display: flex;align-items: center;}
.st-client .client-group:last-child::before{content: none;}
.st-client .client-group .list-item {animation: scroll 20s linear infinite;display: flex;width: auto;}
.client-group:nth-of-type(even){ justify-content: flex-end; }
.bg-transition-clr{
    background: rgb(18,0,180);
    background: linear-gradient(73deg, #7361C0 0%, #37C0BA 30.19%, #0099FF 52.36%, #FDA0F9 72.73%, #7361C0 96.1% );background-size: 400% 400%;animation: gradient 15s ease infinite;}

@media (max-width: 1199.98px) {	
    .st-client { padding: 50px 0 30px;}
}
@media (max-width: 991.98px) {
    .st-client .client-group .item { width: 15%; height: auto; margin-bottom: 10px;}
    .st-client .client-group .list-item {justify-content: center;}
}
@media (max-width: 767.98px) {	
    .st-client .client-group{padding: 0 15px;}
    .st-client .client-group .item{    width: 25%;}
    .st-client .client-group .item img {object-fit: cover;}
    .st-client .client-group .list-item { justify-content: start;}
}
</style>