<template>
    <header id="sec-header">
        <div class="box-header">
            <div class="box-logo animate__animated  animate__slideInLeft">
                <a href="/"><img src="../assets/image/skin/logo-nex.svg" alt="Digital NEX : Digital Agency in Thailand"></a>
            </div>
            <!--<div class="btn-apply"><a href="/job/apply" class="">Apply job</a></div>-->
            <div class="box-menu animate__animated  animate__slideInRight" @click="toggleNav">
                <div class="groupMenu" id="showMenu">
                    <span class="pan1"></span>
                    <span class="pan2"></span>
                    <span class="pan3"></span>
                </div>
            </div>
        </div>

     
        
    </header>
    <nav class="nx-nav-main">
        <div class="nx-nav-main__wrap position-relative">
            <div class="container container-1600 px-0 " >
                <div class="box-menu-list align-items-lg-center ">
                    <div class="row w-100">
                        <div class="col-12 col-lg-8">
                            <ul class="list-menu">
                                <li :class="$route.name == '' || $route.name === 'home' ? 'active' : '' "><a href="/">Home</a></li>
                                <li :class="$route.name == 'about' ? 'active' : '' " ><a class="" href="about">About</a></li>
                                <li :class="$route.name == 'works' ? 'active' : '' "><a class="" href="works">Works</a></li>
                                <li :class="$route.name == 'services' ? 'active' : '' "><a class="" href="services">Services</a></li>
                                <li :class="$route.name == 'contact' ? 'active' : '' "><a class="" href="contact">Contact</a></li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-4 d-lg-block d-none">
                            <ul class="list-menu-end text-start d-lg-block d-none cont-r-animate">
                                <li><a class="tel"  href="tel:+66818191122"><span class="justify-content-start"><img src="@/assets/image/skin/icon-phone.svg" class="svg" alt=""><span>+6681.819.1122</span></span></a></li>
                            </ul>
                            <div class="fw text-start mt-4 d-lg-block d-none">
                                <p class="text-light mb-1 cont-r-animate"><small>Follow us</small></p>
                                <ul class="list-menu-end text-start d-flex align-items-center cont-r-animate">
                                    <li><a class="btn-icon-only " href=""><span><img src="@/assets/image/skin/icon-facebook.svg" alt=""></span></a></li>
                                    <li><a class="btn-icon-only " href=""><span><img src="@/assets/image/skin/icon-linkin.svg" alt=""></span></a></li>
                                </ul>
                            </div>

                            <!-- <div class="fw text-start mt-lg-5  mt-4 box-content normal">
                                <h5 class="mb-1 txt-ctr-green cont-r-animate"><b>The NEX Secret</b></h5>
                                <ul class="style-disc text-light">
                                    <li class="cont-r-animate">Behind the scene</li>
                                    <li  class="cont-r-animate"><a href="https://www.google.com/maps/place/DigitalNEX+HQ/@13.7839678,100.5805592,17z/data=!3m1!4b1!4m6!3m5!1s0x30e29ddcfde3a4cd:0xaeab469a374ff91c!8m2!3d13.7839678!4d100.5831395!16s%2Fg%2F11gsmkzmf5" target="_blank"></a> Head Quarter</li>
                                    <li  class="cont-r-animate">Partner</li>
                                </ul>
                            </div> -->

                        </div>
                    </div>

                    <div class="menu-footer-m d-lg-none">
                        <div class="fw text-start  w-50">
                            <ul class="list-menu-end text-start d-flex align-items-center ">
                                <li  class="cont-r-animate"><a class="btn-icon-only" href="https://www.facebook.com/people/Digitalnex/100039395115400/"><span><img src="@/assets/image/skin/icon-facebook.svg" alt=""></span></a></li>
                                <li  class="cont-r-animate"><a class="btn-icon-only" href="https://www.linkedin.com/company/digitalnex/about/"><span><img src="@/assets/image/skin/icon-linkin.svg" alt=""></span></a></li>
                            </ul>
                        </div>
                        <ul class="list-menu-end text-start fw w-50 text-end">
                            <li  class="cont-r-animate m-0"><a class="tel" href="tel:+66818191122"><span><img src="@/assets/image/skin/icon-phone.svg" class="svg" alt=""><span>+6681.819.1122</span></span></a></li>
                        </ul>
                    </div>
                </div>
            </div> 
        </div>
    </nav>
</template>

<script>
import { TweenMax } from 'gsap';
export default {
    
    data(){
        return{
            deviceType: '',
        }
    },
    mounted() {
        window.addEventListener('scroll', function() {
            var header = document.querySelector('.box-header');
            if (window.scrollY >= 50) {
                header.classList.add('head-down');
            } else {
                header.classList.remove('head-down');
            }
        });
    },
    methods: {
        toggleNav() {
            if (document.body.classList.contains('has-nav-open')) {
                document.body.classList.remove('has-nav-open');

                if (document.querySelector('.my-scrollbar') != null) {
                    document.querySelector('.my-scrollbar').style.filter = "blur(0) ";
                }else{
                    document.querySelector('.wrapper').style.filter = "blur(0) ";
                }

               
                let items = document.querySelectorAll('.list-menu li');
                items.forEach((element) => {
                    element.removeAttribute('style');
                });
               
            } else {
                
                document.body.classList.add('has-nav-open');

                if (document.querySelector('.my-scrollbar') != null) {
                    document.querySelector('.my-scrollbar').style.filter = "blur(8px) ";
                }else{
                    document.querySelector('.wrapper').style.filter = "blur(8px) ";
                }
               
                this.muenu_animate();
            }
            
        },
        closeNav(){
            if (document.body.classList.contains('has-nav-open')) {
                document.body.classList.remove('has-nav-open');

                if (document.querySelector('.my-scrollbar') != null) {
                    document.querySelector('.my-scrollbar').style.filter = "blur(0) ";
                }else{
                    document.querySelector('.wrapper').style.filter = "blur(0) ";
                }

               
                let items = document.querySelectorAll('.list-menu li');
                items.forEach((element) => {
                    element.removeAttribute('style');
                });
                document.body.removeAttribute('style');
               
            }
        },
        muenu_animate() {
            let items = document.querySelectorAll('.list-menu li');
            TweenMax.staggerFromTo(items, 1, { opacity: 0, y:200}, { opacity: 1, y:0 }, 0.1);

            let conts = document.querySelectorAll('.cont-r-animate');
            TweenMax.staggerFromTo(conts, 1, { opacity: 0, y:200}, { opacity: 1, y:0 }, 0.1);
            
        },

    }
}
</script>

<style>
    .nx-nav-main{position: fixed;width: 100%;float: left;height: 100%;background: rgba(0, 0, 0, 0.85);opacity: 0;z-index: -1;transition:all ease-in-out .5s;}
    .has-nav-open .nx-nav-main{opacity: 1;z-index: 9;}
    /* .has-nav-open .my-scrollbar{ filter: blur(8px);} */
    .my-scrollbar{transition:all ease-in-out .5s ;}
    .nx-nav-main__wrap{height: 100%;}
    .nx-nav-main__wrap .box-menu-list{height: 100vh;display: flex;justify-content: center;width: 100%;}
    .nx-nav-main__wrap .list-menu{text-align: left;}
    .nx-nav-main__wrap .list-menu li{margin-bottom: 20px;opacity: 0;}
    .nx-nav-main__wrap .list-menu li a{color: #fff;text-transform: uppercase;font-weight: 400;font-size: 5em; line-height: 1;}
    .nx-nav-main__wrap .list-menu li.active a{font-family: 'Didot';color: #00FFAC;}
    .nx-nav-main__wrap .list-menu-end li a.tel{font-size: 2.5em;margin-bottom: 20px;color: #fff;}
    .nx-nav-main__wrap .list-menu-end li a.tel > span{display: flex;align-items: end;}
    .nx-nav-main__wrap .list-menu-end li a.tel > span > span{ line-height: 0.7;margin-left: 10px;}
    .nx-nav-main__wrap .list-menu-end li a.tel > span img,.nx-nav-main__wrap .list-menu-end li a.tel > span svg{height: 55px;}
    /* ----------------- header */
    header { z-index:10; position:fixed; background:none; width:100%; float:left;transition: all ease .5s;}
    header.dark {}
    .box-header { float:left; width:100%;position: relative;z-index: 11;    display: flex;align-items: center;justify-content: space-between; transition: all ease .4s;}
    .box-logo { float:left; width:auto;padding: 0 20px;}
    .box-logo img{height: 40px; float: left;}
    .outer-nav.horizontal { width:170px;}
    .outer-nav a  { font-family:"bebas_neuebold"; text-align:left;  clear:both; float:left;}
    .outer-nav a:hover { color:#00a99d;} 
    .container-main {box-shadow: 0 0 0px 2px #000 inset; background-color:#111; max-width:none;}

    .box-menu { float:right; width:auto; position:relative;padding: 25px 20px;}	
    .box-menu .groupMenu {margin: 0 auto;width: 26px; cursor:pointer; height:16px; position:relative; float:right;}
	.box-menu .groupMenu span { position:absolute;width: 26px;height: 2px;background-color: #fff;float: left;margin-bottom: 4px; overflow:hidden;-webkit-transition:ease .4s; transition:ease .4s; }
	.box-menu .groupMenu span:last-child {margin-bottom: 0px;}
	.box-menu .groupMenu .pan1 { top:0px; left:0px; transform-origin:center center;-webkit-transform-origin:center center;}
	.box-menu .groupMenu .pan2 { top:7px; left:0px; transform-origin:center center;-webkit-transform-origin:center center; width: 20px;}
	.box-menu .groupMenu .pan3 { top:14px; left:0px; transform-origin:center center;-webkit-transform-origin:center center;}
	.box-menu .btn-menutext { color:#fff; float:right; text-transform:uppercase; font-size:10px; padding-top:3px; margin-right:15px; letter-spacing:1px;}
	.box-menu:hover span:before { left:0px; }
	.closeMenu { position:absolute; top:20px; right:20px; width:50px; height:50px; z-index:-1; cursor:pointer;}
	.has-nav-open .groupMenu .pan1 { left: 0; transform:rotate(45deg);-ms-transform:rotate(45deg);-webkit-transform:rotate(45deg); top:6px; z-index:2; height:2px;}
	.has-nav-open .groupMenu .pan2 { left: 0; width:0px; height:0px;}
	.has-nav-open .groupMenu .pan3 { left: 0; transform:rotate(-45deg);-ms-transform:rotate(-45deg);-webkit-transform:rotate(-45deg); top:6px; z-index:2; height:2px;}
	.has-nav-open .closeMenu { z-index:12;}
	.has-nav-open .box-menu { z-index:12;}

    .list-menu-end li{margin-right: 10px;}
    .btn-icon-only span{display: flex;align-items: center;}
    nav .nx-nav-main__wrap .list-menu li  a.router-link-exact-active{color: #00FFAC;font-family:'Linotype Didot Std' ;}
@media (max-width: 1199.98px) {	
    .nx-nav-main__wrap .list-menu li a{font-size: 3em;}
    .nx-nav-main__wrap .list-menu li{margin-bottom: 30px;}

    .menu-footer-m {position: absolute ;bottom: 0;left: 0;width: 100%;display: flex;align-items: center;}
    .nx-nav-main__wrap .list-menu-end li a.tel > span{align-items: center;justify-content: flex-end;}
    .nx-nav-main__wrap .list-menu-end li a.tel{font-size: 1.2em;}
    .nx-nav-main__wrap .list-menu-end li a.tel > span img, .nx-nav-main__wrap .list-menu-end li a.tel > span svg{height: 37px;}
    .border-lg-top{border-top:  1px solid rgba(255, 255, 255, 0.2);}
    .btn-icon-only img,.btn-icon-only svg{height: 20px;}
    .list-menu-end li{margin-right: 20px;}
    nav{padding: 20px;}

    .nx-nav-main__wrap .box-menu-list{max-height: calc(100vh - 130px);overflow: hidden;overflow-y: scroll;margin-top: 30px;}
    .nx-nav-main__wrap {height: calc(100% - 20px);}
    .nx-nav-main__wrap .box-menu-list::-webkit-scrollbar {display: none;}
    .nx-nav-main__wrap .box-menu-list {-ms-overflow-style: none;   scrollbar-width: none;  }
}
@media (max-width: 767.98px) {	
    .box-menu { padding:20px;}
    .nx-nav-main { padding-top:60px ;}
    .box-logo img { height: 30px;}
    .box-header.head-down  { background-color: rgba(0,0,0,0.8);}
}
</style>